import { observable, action, decorate, toJS } from "mobx";
import { lazy } from "react";
import { persist } from "mobx-persist";
import Floorplanning from "../pages/Floorplanning";
import { cloneDeep } from "@apollo/client/utilities";
import filterToolsAndProducts from "../utils/FilterToolsAndProducts";
import { datadogRum } from "@datadog/browser-rum";
//const VirtualPhoto = lazy(() => import('../pages/VirtualPhotoOld'));
const VirtualPhoto = lazy(() => import("../pages/VirtualPhoto"));
const Video = lazy(() => import("../pages/Video"));
const InteriorExperienceSplit = lazy(() =>
  import("../pages/InteriorExperienceSplit")
);
const InteriorExperience = lazy(() => import("../pages/InteriorExperience"));
const ComponentSwitcher = lazy(() => import("../components/ComponentSwitcher"));
const SliderGallery = lazy(() =>
  import("../components/slider-gallery/SliderGallery")
);
const StandaloneImage = lazy(() => import("../components/StandaloneImage"));
const VirtualModel = lazy(() => import("../pages/VirtualModel"));
const VirtualTour = lazy(() => import("../pages/VirtualTour"));

const Planimetrie = lazy(() => import("../pages/Planimetrie"));
const BuildingsPage = lazy(() =>
  import("../components/planimetrie/BuildingsPage")
);
const PlanimetriaAppartamento = lazy(() =>
  import("../components/planimetrie/PlanimetriaAppartamento")
);
const PlanimetrieDiPiano = lazy(() =>
  import("../components/planimetrie/PlanimetrieDiPiano")
);
const Servizi = lazy(() => import("../pages/Servizi"));
const PlanimetriaAppartamentoSplit = lazy(() =>
  import("../components/planimetrie/PlanimetriaAppartamentoSplit")
);
const FilterPage = lazy(() => import("../pages/FilterPage"));
const NoComponent = lazy(() => import("../pages/NoComponent"));

const componentsMap = {
  VirtualPhoto: VirtualPhoto,
  VirtualTour: VirtualTour,
  InteriorExperienceSplit: InteriorExperienceSplit,
  InteriorExperience: InteriorExperience,
  Video: Video,
  ComponentSwitcher: ComponentSwitcher,
  SliderGallery: SliderGallery,
  StandaloneImage: StandaloneImage,
  Floorplanning: Floorplanning,
  VirtualModel: VirtualModel,
  Planimetrie: Planimetrie,
  BuildingsPage: BuildingsPage,
  PlanimetrieDiPiano: PlanimetrieDiPiano,
  PlanimetriaAppartamento: PlanimetriaAppartamento,
  Servizi: Servizi,
  PlanimetriaAppartamentoSplit: PlanimetriaAppartamentoSplit,
  FilterPage: FilterPage,
  NoComponent: NoComponent,
};

class Store {
  constructor() {
    this.projectId = "";
    this.projectName = "";
    this.projectTitle = "";
    this.projectSlogan = "";
    this.projectDescription = "";
    this.backgroundImage = "";
    this.bigLogo = "";
    this.smallLogo = "";
    this.smallLogoNavbar = "";
    this.smallLogoText = "";
    this.smallLogoNavbar = "";
    this.enabledTools = {};
    this.homePage = {};
    this.videocallMode = false;
    this.videocallIsVendor = false;
    this.skipLogin = true;
    this.testMode = false;
    this.hostKey = "";
    this.goFullscreen = true;
    this.isNavbarLink = true;
    this.disableNoTap = false;
    this.latuacasaUrl = null;
    this.cmsModal = false;
    this.currentPage = "HomePage";
    this.currentPath = "";
    this.previewConfiguration = null;
    this.selectPageTiles = false;
    this.cmsSettings = false;
    this.modifyPageTiles = false;
    this.defaultPreviewConfiguration = null;
    this.numAssets = null;
    this.numAssetsLoaded = 0;
    this.numAssetsError = 0;
    this.settings = null;
    this.customCss = null;
    this.firstStart = false;
    this.loggedUser = null;
    this.projectUrl = null;
    this.componentMode = false;
    this.baseUrl = undefined;

    this.products = [];
  }

  setAssetsByObject(obj) {
    if (obj) {
      if (this.projectId !== obj.id) {
        this.previewConfiguration = null;
        this.settings = null;
        this.customCss = null;
      }

      this.projectId = obj.id;
      this.projectName = obj.name;
      this.projectTitle = obj.displayName;
      this.projectSlogan = obj.pageTitles.neurosales;
      this.projectDescription = obj.payoff;
      this.enabledTools = obj.enabledTools;
      if (obj.neurosalesConfig) {
        this.defaultPreviewConfiguration = obj.neurosalesConfig;
        this.backgroundImage = obj.neurosalesConfig.backgroundImage;
        this.bigLogo = obj.neurosalesConfig.bigLogo;
        this.smallLogo = obj.neurosalesConfig.smallLogo;
        this.smallLogoNavbar = obj.neurosalesConfig.smallLogoNavbar;
        this.smallLogoText = obj.neurosalesConfig.smallLogoText;
        this.smallLogoNavbar = obj.neurosalesConfig.smallLogoNavbar;
        this.homePage = obj.neurosalesConfig.homePage;
        this.skipLogin = obj.neurosalesConfig?.skipLogin ?? true;
        this.settings = obj.neurosalesConfig.settings;
        this.customCss = obj.neurosalesConfig.customCss;
      } else if (
        !(this.previewConfiguration && this.previewConfiguration.configuration)
      ) {
        // se non c'è preview e la configurazione è vuota allora mostro selezione tiles
        this.setFirstStart(true);
      }
      this.hostKey = obj.hostKey;
      this.projectUrl = obj.projectUrl;
    }
  }

  getSettings() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.settings
      : this.settings;
  }

  getCustomCss() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.customCss
      : this.customCss;
  }

  getBackgroundImage() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.backgroundImage
      : this.backgroundImage;
  }

  getBigLogo() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.bigLogo
      : this.bigLogo;
  }

  getSmallLogo() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.smallLogo
      : this.smallLogo;
  }

  getSmallLogoText() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.smallLogoText
      : this.smallLogoText;
  }

  getSmallLogoNavbar() {
    return this.previewConfiguration && this.previewConfiguration.configuration
      ? this.previewConfiguration.configuration.smallLogoNavbar
      : this.smallLogoNavbar;
  }

  getHomePage() {
    const homePageData =
      this.previewConfiguration && toJS(this.previewConfiguration).configuration
        ? toJS(this.previewConfiguration).configuration.homePage
        : toJS(this.homePage);

    let boxes = [];
    if (homePageData?.boxes) {
      boxes = homePageData.boxes.map((homepageBox) => {
        const box = cloneDeep(homepageBox);
        box.componentName = box.component;
        box.component = componentsMap[box.component];
        if (box.componentParams && box.componentParams.components)
          box.componentParams.components.forEach((innerBox) => {
            innerBox.componentName = innerBox.component;
            innerBox.component = componentsMap[innerBox.component];
          });
        return box;
      });
    }

    return { boxes };
  }

  showNoteLegali() {
    const homePageData = toJS(this.homePage);
    return homePageData && homePageData.showNoteLegali;
  }

  setVideocallMode(videocallMode) {
    this.videocallMode = videocallMode;
  }

  setVideocallIsVendor(videocallIsVendor) {
    this.videocallIsVendor = videocallIsVendor;
  }

  setGoFullscreen(goFullscreen) {
    this.goFullscreen = goFullscreen === "true";
  }

  setIsNavbarLink(isNavbarLink) {
    this.isNavbarLink = isNavbarLink === "true";
  }

  setDisableNoTap(disableNoTap) {
    this.disableNoTap = disableNoTap === "true";
  }

  setLatuacasaUrl(latuacasaUrl) {
    this.latuacasaUrl = latuacasaUrl;
  }

  setCmsModal(val) {
    this.cmsModal = val;
  }

  setCurrentPage(name, path) {
    this.currentPage = name;
    this.currentPath = path;
  }

  setPreviewConfiguration(configuration) {
    this.previewConfiguration = configuration;
  }

  setSelectPageTiles(selectPageTiles) {
    this.selectPageTiles = selectPageTiles;
  }

  setCmsSettingsPage(settings) {
    this.cmsSettings = settings;
  }

  setFirstStart(firstStart) {
    this.firstStart = firstStart;
  }

  setModifyPageTiles(modifyPageTiles) {
    this.selectPageTiles = modifyPageTiles;
    this.modifyPageTiles = modifyPageTiles;
  }

  setNumAssets(numAssets) {
    this.numAssets = numAssets;
  }

  setNumAssetsLoaded(numAssetsLoaded) {
    this.numAssetsLoaded = numAssetsLoaded;
  }

  setNumAssetsError(numAssetsError) {
    this.numAssetsError = numAssetsError;
  }

  setTestMode(testMode) {
    this.testMode = testMode;
  }

  setLoggedUser(val) {
    this.loggedUser = val;
    // if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
    datadogRum.setUser({
      id: val.id,
      name: val.firstName,
      surname: val.lastName,
      email: val.email,
      role: val.role,
    });
    // }
  }

  setComponentMode(value) {
    this.componentMode = value;
  }

  setBaseUrl(val) {
    this.baseUrl = val;
  }

  setProducts(value, toolStatus) {
    if (toolStatus) {
      const products = [];
      value.forEach((tool) => {
        const status = toolStatus.filter((ts) =>
          filterToolsAndProducts(ts, tool)
        )[0];
        if (status) {
          const myTool = cloneDeep(tool);
          myTool.status = status.status;
          products.push(myTool);
        }
      });

      this.products = [...products];
    } else {
      this.products = [...value];
    }
  }
}

decorate(Store, {
  projectId: [persist, observable],
  projectTitle: [persist, observable],
  projectSlogan: [persist, observable],
  projectDescription: [persist, observable],
  backgroundImage: [persist, observable],
  bigLogo: [persist, observable],
  smallLogoWhite: [persist, observable],
  smallLogoColored: [persist, observable],
  smallLogoNavbar: [persist, observable],
  homePage: [persist("object"), observable],
  skipLogin: [observable],
  testMode: [persist, observable],
  goFullscreen: [persist, observable],
  isNavbarLink: [persist, observable],
  disableNoTap: [persist, observable],
  latuacasaUrl: [persist, observable],
  previewConfiguration: [persist("object"), observable],
  defaultPreviewConfiguration: [persist("object"), observable],
  settings: [persist("object"), observable],
  customCss: [persist("object"), observable],
  numAssets: observable,
  numAssetsLoaded: observable,
  numAssetsError: observable,
  virtualPhoto: observable,
  interiorExperience: observable,
  virtualTour: observable,
  video: observable,
  interiorDesign: observable,
  capitolato: observable,
  homeConfigurator: observable,
  lifestyle: observable,
  book: observable,
  standaloneImage: observable,
  virtualModel: observable,
  videocallMode: observable,
  videocallIsVendor: observable,
  cmsModal: observable,
  currentPage: observable,
  currentPath: observable,
  selectPageTiles: observable,
  cmsSettings: observable,
  modifyPageTiles: observable,
  firstStart: observable,
  loggedUser: observable,
  componentMode: observable,
  baseUrl: observable,
  products: observable,
  enabledTools: observable,

  setAssetsByObject: action.bound,
  setVideocallMode: action.bound,
  setVideocallIsVendor: action.bound,
  setCmsModal: action.bound,
  setCurrentPage: action.bound,
  setPreviewConfiguration: action.bound,
  getSettings: action.bound,
  getCustomCss: action.bound,
  getBackgroundImage: action.bound,
  getBigLogo: action.bound,
  getSmallLogo: action.bound,
  getSmallLogoText: action.bound,
  getSmallLogoNavbar: action.bound,
  setSelectPageTiles: action.bound,
  setCmsSettingsPage: action.bound,
  setFirstStart: action.bound,
  setModifyPageTiles: action.bound,
  setTestMode: action.bound,
  setLoggedUser: action.bound,
  setComponentMode: action.bound,
  setBaseUrl: action.bound,
  setProducts: action.bound,
});

export default Store;
